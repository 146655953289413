<template>
  <section class="container-fluid pt-5" :id="$props.id?$props.id:'grouppricing'" :style="($props.background?'background:'+$props.background:'')">
    <h2 class="text-center pb-4 title-grouppricing" v-html="$props.title"></h2>
    <b-row class="justify-content-md-center p-2">
      <b-col md="2" class="m-1 p-0" v-for="item in $props.items" :key="item.id">
        <b-card
                text-variant="dark"
                :img-src="item.image"
                img-height="200px"
                class="text-center mb-2 p-0 pb-3 bg-cardprice"
                tag="article"
        >
          <b-card-text class="mt-0" style="min-height: 6em;">
            <h5 v-html="item.name"></h5>
            <h6 v-html="item.description"></h6>
          </b-card-text>
          <b-list-group class="mb-0">
            <b-list-group-item v-for="feature in item.pricings" :key="feature.id" class="bg-price mt-0 p-1">
              {{feature.concept}} <br>
              <b>{{feature.value}}</b>
            </b-list-group-item>
          </b-list-group>
        </b-card>
      </b-col>
    </b-row>
  </section>
</template>

<script>
export default {
  name: "GroupPricing",
  props: {
    items: Array,
    title: String,
    background: String,
    id: String
  }
}
</script>

<style scoped>
.bg-price{
  background-color: #f8f8f8;
  font-weight: normal;
  font-size: 0.7rem;
  color: #222222;
  border:0;
  border-bottom: 1px dashed #aaaaaa;
}
.bg-cardprice{
  background-color: #fefefe;
  color: #000000;
}
.title-grouppricing{
  color: #fefefe;
  font-size: 2em;
}
</style>
