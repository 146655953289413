<template>
  <section id="videoDiv" class="border p-0 m-0">
    <video id="video1" preload="" autoplay="" muted="" playsinline="" loop="">
      <source :src="$props.video" type="video/mp4">
    </video>
    <div id="videoMessage">
      <h1 class="title"> {{$props.title}}</h1>
      <h4 class="subtitle mb-5"> {{$props.subtitle}}</h4>
      <div class="container-fluid">
        <ButtonComponent class="mr-2" v-for="button in $props.buttons" :key="button.id" :text="button.text" :variant="button.variant" :href="button.link"></ButtonComponent>
      </div>
    </div>
  </section>
</template>

<script>
import ButtonComponent from '../buttons/ButtonComponent.vue';
export default {
  name: 'BannerProductComponent',
  components: {ButtonComponent},
  props: {
    title: String,
    subtitle: String,
    image: String,
    buttons: Array,
    video: String
  }
}
</script>
<style lang="scss" scoped>
  .title{
    margin-top:0.5em;
    font-size:6em;
    font-weight: 300;
    color:#ffffff;
    text-shadow: 2px 2px #222222;
    text-align: center;
  }
  .subtitle{
    font-size:2.5em;
    color: #fefefe;
    text-shadow: 1px 1px #111111;
    width: 60%;
    text-align: center;
    font-weight: 600;
  }
  #videoDiv {
    width: 100%; height: 400px; position: relative; overflow: hidden;
  }
  #video1 {
    margin: auto; display: block; width: 100%; height: auto;
  }
  #videoMessage {
    position: absolute; top: 0; left: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: fit-content();
    font-weight: bold;
    margin-top: 1.2em;
  }
</style>
