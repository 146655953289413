<template>
  <div id="app">
      <HeaderComponent name="CDA La Isla" :links="sections"  />
      <router-view/>
      <FooterComponent name="CDA la Isla" url="cdalaisla.com"
                       slogan="Revisión Técnico Mecánica CDA La Isla en Bucaramanga"
                       :links="links" :logo="logo" :info="info" :logo2="logo2" />
  </div>
</template>

<script>
import HeaderComponent from "./packages/jumichica.ui/components/headers/HeaderComponent.vue";
import FooterComponent from "@/packages/jumichica.ui/components/footers/FooterComponent.vue";
export default {
  name: 'App',
  data: function(){
    return {
      sections:[
        { id: 1, name: "Inicio", url: "/" },
        /*{ id: 2, name: "Servicios", url: "/servicios" },*/
        { id: 3, name: "Tarifas", url: "/tarifas" },
        { id: 7, name: "SOAT", url: "/soat" },
        /*{ id: 4, name: "Acerca de nosotros", url: "/empresa" },*/
        { id: 5, name: "Preguntas Frecuentes", url: "/faqs" },
        { id: 6, name: "Contacto", url: "/contacto" },
      ],
      links:[
        {
          id:1,
          name:"Servicios",
          items: [
            { id:1, name:"...", url: "/link" }
          ]
        },
        {
          id:2,
          name:"Empresa",
          items: [
            { id:1, name:"...", url: "/link" }
          ]
        }
      ],
      info:[
        {id: 1, value: "Calle 52 # 17a-38 Bucaramanga-Santander"},
        {id: 2, value: "PBX: (607) 6854673 - 3046687427 - 3004714132"},
        {id: 3, value: "mercadeo@cdalaisla.com"}
      ],
      //logo: "https://cdn.systemico.net/wabot/customers/cdalaisla/cdalaisla_logo.png",
      logo: "https://cdn.systemico.net/ducksites/customers/cdalaisla/mheaders/logo_laisla_carro.png",
      logo2: null
    }
  },
  components: {
    FooterComponent,
    HeaderComponent,
  },
  beforeMount() {
    let hostname = window.location.hostname;
    let datos = {
      domain: hostname,
      type: 1
    }
    this.$store.dispatch('fetchSections', datos).then(response => {
      if(response.CODE==1){
        this.sections = response.DATA
      }
    })
    datos = {
      domain: hostname,
      type: 2
    }
    this.$store.dispatch('fetchSections', datos).then(response => {
      if(response.CODE==1){
        this.links = response.DATA
      }
    })
    this.logo2 = "https://cdn.systemico.net/ducksites/customers/cdalaisla/mheaders/logo-vigilado-principal.webp"
  }
}
</script>

<!-- GENERAL STYLE FOR ALL APPLICATION -->
<style lang="scss">
@import "./packages/jumichica.ui/variables.scss";
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

*{
  font-size:16px;
  font-family: 'Noto Sans', sans-serif;
}
#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #222222;
}

a{
  color: #2c3e50;
}
a:active{
  color: #2c3e50;
}
</style>
