<template>
  <section class="text-center p-4" :style="'background:'+$props.background+';color:'+$props.textColor">
    <b-row>
      <b-col cols="8">
        <h3 class="message pt-2" v-html="$props.message"></h3>
        <h5 class="author" v-html="$props.author"></h5>
        <b-img v-if="$props.icon2 && $props.icon2!=''" :src="$props.icon2" style="height: 30px" fluid alt="Responsive image"></b-img>
      </b-col>
      <b-col cols="4" class="align-items-center align-content-center text-center">
        <b-img :src="$props.icon" style="width:170px;" fluid alt="Responsive image"></b-img>
      </b-col>
    </b-row>
  </section>
</template>

<script>
export default {
  name: 'BannerTipComponent',
  props: {
    message: String,
    icon: String,
    icon2: String,
    author: String,
    background: String,
    textColor: String
  }
}
</script>
<style>
  @import "../../variables.scss";
  a{
    color: #1e934c;
  }
  .author{
    font-size: 1.5em;
    margin-top: 1em;
    /*margin-bottom: 2em;*/
  }
  .message{
    font-size: 2em;
    margin:auto;
    width: 100%;
  }
</style>
