<template>
  <div id="contactandmap" class="container-fluid contactandmap p-4">
    <h2>{{title}}</h2>
    <h4>{{subtitle}}</h4>
    <b-row class="mt-3">
      <b-col cols="6">
        <b-card class="" style="margin:auto;max-width: 350px;" header="">
          <b-form @submit.stop.prevent>
            <!--FULL NAME -->
            <label for="text-password" class="label">Nombre Completo</label>
            <b-form-input class="input" type="text" id="full_name" v-model="name"
                          aria-describedby="name-help-block" placeholder="Escribe tu nombre completo."></b-form-input>
            <b-form-text id="name-help-block" class="help">
              Escribe tu nombre completo para ponernos en contacto.
            </b-form-text>
            <!--PHONE NUMBER -->
            <label for="text-password" class="label">Número de teléfono</label>
            <b-form-input class="input" type="phone" id="phone" v-model="phone"
                          aria-describedby="name-help-block" placeholder="Escribe tu número de teléfono."></b-form-input>
            <b-form-text id="name-help-block" class="help">
              Escribe tu número de teléfono.
            </b-form-text>
            <!--PHONE EMAIL -->
            <label for="text-password" class="label">Email</label>
            <b-form-input class="input" type="email" id="email" v-model="email"
                          aria-describedby="name-help-block" placeholder="Escribe tu email completo."></b-form-input>
            <b-form-text id="name-help-block" class="help">
              Escribe tu email de contacto.
            </b-form-text>
            <!-- NEED -->
            <label for="text-password" class="label">Detalle de la Necesidad</label>
            <b-form-textarea
              id="txtmsg"
              v-model="message"
              class="input"
              placeholder="Describe claramente tu necesidad, se concreto, si deseas puedes mencionar aspectos técnicos ó lo que consideres relevante."
              rows="3"
              max-rows="6"
            ></b-form-textarea>
            <b-form-text id="name-help-block" class="help">
              Escribe tu email de contacto.
            </b-form-text>
            <div class="text-center pt-2">
              <ButtonSave class="m-0" variant="primary" size="medium" text="Enviar" @click="sendmsg()" />
            </div>
            <div class="text-center pt-2">
              <small class="text-muted" style="font-size:12px;">Al enviar este mensaje aceptas nuestros términos de uso y políticas de privacidad.</small>
            </div>
           </b-form>
        </b-card>
      </b-col>
      <b-col cols="6" class="p-2">
        <iframe class="container-fluid" height="100%" src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15836.558647839292!2d-73.1197117!3d7.1098089!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8e683fc4d14a2e99%3A0x4ca1f69d15b4532a!2sC.D.A.%20Island%20Mechanical%20Technical%20Review!5e0!3m2!1sen!2sco!4v1705092436025!5m2!1sen!2sco"
                style="border:2px solid #fefefe;margin:auto;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import ButtonSave from "../buttons/ButtonComponent.vue";
import axios from 'axios'
export default {
  name: "ContactAndMap",
  components: {
    ButtonSave
  },
  props:{
    title: String,
    subtitle: String,
  },
  data: function () {
    return {
      send: {
        text: "Enviar Solicitud",
        class: "button--secundary",
        link: "",
      },
      name: '',
      phone: '',
      email: '',
      message: ''
    }
  },
  methods:{
    sendmsg: function(){
      let formData = new FormData();
      formData.append("nombre_cliente", this.name);
      formData.append("email_cliente", this.email);
      formData.append("telefono_cliente", this.phone);
      formData.append("descripcion", 'Mensaje enviado desde web site Systemico: '+this.message);
      formData.append('api_client', clienteid);

      //this.$store.commit('setLoading', true);

      axios.post('https://suppmail.systemico.net/customer/cliente_ticket_crear',formData).then(response => {
        let res = response.data
        if (res.CODIGO == 1) {
          this.$swal('Solicitud remita exitosamente, muy pronto nuestro equipo te contactara')
          this.name = ''
          this.phone = ''
          this.email = ''
          this.message = ''
          //this.$store.commit('setNotification', { state: true, title: 'Éxito', text: 'Recomendación enviada correctamente' });
          //this.close();
        }
        else if(res.CODIGO != 6) {
          //this.$store.commit('setNotification', { state: true, title: 'Error', text: response.MENSAJE });
        }
      });

      //this.$store.commit('setLoading', false);

    }
  }
}
</script>

<style lang="scss" scoped>
  .contactandmap{
    background:#20c997;
    h2{
      color:#fefefe;
    }
    h4{
      color:#dddddd;
      font-weight: 300;
    }
  }
  .label{
    font-size:16px;
    font-weight:bold;
    color:#444444;
  }
  .input{
    font-size:16px;
    font-family:"Roboto", sans-serif;
    margin-bottom:1em;
  }
  .help{
    font-size:11px;
    display: none;
  }
</style>
