import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'
Vue.use(Vuex)
const apiAxios = axios.create({
  headers: { 'Content-Type': 'application/json' }
})

Vue.use(Vuex, apiAxios)

export default new Vuex.Store({
  state: {
  },
  mutations: {
  },
  actions: {
    fetchComponents ({ commit }, payload) {
      return axios.post('get_components', payload).then((response) => {
        try {
          return response.data
        } catch (error) {
          console.error(error)
          alert('Problemas de conexion con el webservice, hemos notificado a soporte')
        }
      })
    },
    fetchSections ({ commit }, payload) {
      return axios.post('get_list_sections', payload).then((response) => {
        try {
          return response.data
        } catch (error) {
          console.error(error)
          alert('Problemas de conexion con el webservice, hemos notificado a soporte')
        }
      })
    }
  },
  modules: {
  }
})
