<template>
  <section id="faqs" class="faq" role="tablist" :style="'background:'+$props.background+';color:'+$props.textColor">
    <h2 class="display-3 text-center font-weight-bold" v-html="$props.title"></h2>
    <h4 class="display-4 text-center" v-html="$props.subtitle"></h4>
    <b-card no-body class="mb-1" v-for="faq in faqs" :key="faq.id">
      <b-card-header header-tag="header" class="p-1" role="tab">
        <b-button block v-b-toggle="'accordion-'+faq.id" variant="primary">{{faq.title}}</b-button>
      </b-card-header>
      <b-collapse :id="'accordion-'+faq.id" accordion="my-accordion" role="tabpanel">
        <b-card-body>
          <b-card-text class="text-dark">{{ faq.content }}</b-card-text>
        </b-card-body>
      </b-collapse>
    </b-card>
  </section>
</template>

<script>
export default {
  name: 'FaqProductComponent',
  props: {
    title: String,
    subtitle: String,
    background: String,
    textColor: String,
    faqs: Array
  },
}
</script>
<style lang="scss" scoped>
  .faq{
    font-size:1.2em;
    padding-left:3em;
    padding-right:3em;
    background:#f8f8f8;
    padding-top:2em;
    padding-bottom:2em;
    border-top: 2px solid #aaaaaa;
    h2{
      font-size: 2em;
      font-weight: normal;
      color: #222222;
    }
    h4{
      font-size: 1.4em;
      color:#aaaaaa;
    }
  }
  .card-header {
    font-size: 2.2em;
    background: #fefefe;
    button{
        font-size: 0.5em;
        background: #fefefe;
        border:none;
        color:#222222;
        border-radius: 0;
        font-weight: 500;
    }
  }
</style>
